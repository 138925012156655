.ant-picker{
    /* background-color: green; */
    /* border: 3px solid #15325b; */
   
    box-shadow: 1px 1px 3px 0px rgba(21, 50, 91, 0.12);
    height: 40px;
}

.ant-picker-focused{
    border: #15325b solid 2px;
}

.ant-picker:hover{
    /* background-color: green; */
    /* border: 3px solid #15325b; */
   
    border: #15325b solid 2px;
 
}

/* .ant-picker-focused{
} */

